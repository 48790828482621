import http from '@/utils/http';

export const getWeapons = (params) => {
    return http.request({
        url: '/v1/games/weapon',
        method: 'get',
        params: params
    })
}
export const updateWeapon = (params) => {
    return http.request({
        url: '/v1/games/weapon',
        method: 'post',
        data: params
    })
}