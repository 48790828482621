<template>
    <div>
      <Modal
        v-model="modal1"
        title="详情信息"
        @on-ok="ok"
        @on-cancel="cancel"
      >
        <Form  :label-width="80" >
          <FormItem label="id" v-if="edit_data.id">
              <Input v-model="edit_data.id" disabled></Input>
          </FormItem>
          <FormItem label="中文名称">
              <Input v-model="edit_data.name_zh"></Input>
          </FormItem>
          <FormItem label="英文名称">
              <Input v-model="edit_data.name_en"></Input>
          </FormItem>
          <FormItem label="logo">
              <imgEditor v-model="edit_data.logo" :prefix="$conf.games.img_prefix" 
                  :dir="$conf.games.games[game_id].img + '/weapon' " 
                  :previewButton='true' :clearButton='true' cdn='games'>
              </imgEditor>
          </FormItem>
          <FormItem label="web_logo">
              <imgEditor v-model="edit_data.web_logo" :prefix="$conf.games.img_prefix" 
                  :dir="$conf.games.games[game_id].img + '/web_weapon' " 
                  :previewButton='true' :clearButton='true' cdn='games'>
              </imgEditor>
          </FormItem>
          <FormItem label="删除">
              <Select v-model="edit_data.deleted" >
                  <Option :value="1" key="1">是</Option>
                  <Option :value="0" key="0">否</Option>
              </Select>
          </FormItem>
        </Form>
      </Modal>

      <Row style="margin-bottom:10px;">
        <Col span="6" style="text-align: left">
          <Button  @click="add()">新增</Button>
        </Col>
      </Row>
      <fitTable :columns="columns" :data="data" :loading="loading"></fitTable>
      <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
      ></Page>
    </div>
</template>

<script>
import { getWeapons, updateWeapon } from '@/api/games/weapon';
import imgEditor from '@/components/img-editor/img-editor.vue';
import fitTable from '../../basic/table.vue';

const editButton = (vm, h, params) => {
    // let self = this;
    return h(
      'Button',
      {
        props: {
          type: 'success',
          size: 'small'
        },
        on: {
          click: () => {
            vm.edit_data = JSON.parse(JSON.stringify(params.row))
            vm.edit_row = params.row
            vm.modal1 = true
          }
        }
      },
      '编辑'
    )
}

const delButton = (vm, h, params) => {
    return h(
      'Poptip',
      {
        props: {
          confirm: true,
          title: '您确定要删除这条数据吗?',
          transfer: true
        },
        on: {
          'on-ok': () => {
            vm.delete(params.row.id)
            // 删除本行
            vm.data.splice(params.index, 1)
          }
        }
      },
      [
        h(
          'Button',
          {
            style: {
              margin: '0 5px'
            },
            props: {
              type: 'warning',
              size: 'small'
            }
          },
          '删除'
        )
      ]
    )
}

export default {
    props: {
        game_id: Number,   // 运动id
    },
    data () {
      let self = (window.x = this)
      return {
        search_key: '',
        loading: true,
        total_page: 0,
        page_size: 0,
        current_page: 1,
        edit_data: {
          id: 0,
          name: ''
        },
        edit_row: {},
        modal1: false,
        columns: [
          {
            title: 'id',
            key: 'id'
          },
          {
            title: '中文名称',
            key: 'name_zh'
          },
          {
            title: '英文名称',
            key: 'name_en'
          },
          {
            title: 'logo',
            key: 'logo',
            align: 'center',
            render: function (h, params) {
              let currentRow = params.row
              return currentRow.logo
                ? h('img', {
                  attrs: {
                    src: currentRow.logo ? self.$unit.getImgGameUrl(self.game_id, 'weapon', currentRow.logo)  : '',
                    class: 'cover',
                    style: 'max-width:50px'
                  }
                })
                : ''
            }
          },
          {
            title: 'web_logo',
            key: 'web_logo',
            align: 'center',
            render: function (h, params) {
              let currentRow = params.row
              return currentRow.web_logo
                ? h('img', {
                  attrs: {
                    src: currentRow.web_logo ? self.$unit.getImgGameUrl(self.game_id, 'web_weapon', currentRow.web_logo) : '',
                    class: 'cover',
                    style: 'max-width:50px'
                  }
                })
                : ''
            }
          },
          {
            title: '操作',
            key: 'handlers',
            align: 'center',
            render (h, params) {
              return [editButton(self, h, params), ]
            }
          }
        ],
        data: []
      }
    },
    components: {
      imgEditor,
      fitTable,
    },
    methods: {
      onPageChange (page) {
        this.current_page = page
        this.getData()
      },
      onSearch (search_key) {
        this.current_page = 1
        this.search_key = search_key
        this.getData()
      },
      getData () {
        this.loading = true
        var params = {
          search_key: this.search_key,
          page: this.current_page
        }
        getWeapons(params).then(response => {
          if (response.data.code == 0) {
            this.data = response.data.data.list
            this.total_page = response.data.data.total_page
            this.page_size = response.data.data.page_size
            this.current_page = response.data.data.current_page
            this.loading = false;
          } else {
            this.$Message.error(response.data.msg)
          }
        })
      },
      ok () {
        let self = this
        // 更新新用户名称
        var params = self.edit_data
        // console.log(params);
        updateWeapon(params).then(response => {
          if (response.data.code == 0) {
            // 更新
            if (self.edit_row.id && self.edit_row.id == self.edit_data.id) {
              // self.edit_row.update(self.edit_data);
              for (var item in self.edit_data) {
                self.edit_row[item] = self.edit_data[item]
              };
            } else {
              // 新增
              // self.data.push(self.edit_row);
              self.getData()
            }
            self.$Message.success('操作成功')
            self.edit_row = {}
          } else {
            self.$Message.error(response.data.msg)
          }
        })
      },
      cancel () {
        this.edit_data = {
          logo: '',
          web_logo: ''
        }
      },
      add () {
        this.edit_data = {
          logo: '',
          web_logo: ''
        }
        this.edit_row = {}
        this.modal1 = true
      },
    },
    mounted () {
      this.getData()
    },
    watch: {},
    computed: {}
}
</script>
