<template>
    <div>
        <weaponList :game_id="game_id"></weaponList>
    </div>
</template>

<script>

import weaponList from '@/components/games/weapon/weapon.vue';

export default {
    components: {
        weaponList
    },
    data () {
        let self = (window.x = this)
        return {
            game_id: 2,
        }
    },
    methods: {
    },
    mounted () {
    },
    watch: {},
    computed: {}
}
</script>